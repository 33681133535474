import { asc, desc } from "drizzle-orm";
import {
  boolean,
  decimal,
  index,
  integer,
  pgEnum,
  pgTable,
  real,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

// file table
export const file = pgTable(
  "file",
  {
    id: text("id").primaryKey(),
    contentId: text("contentId").notNull(),
    url: text("url").notNull(),
    mimeType: text("mimeType"),
    name: text("name"),
  },
  (table) => ({
    contentIdIndex: index("file_contentId_idx").on(table.contentId),
  }),
);

// link table
export const link = pgTable(
  "link",
  {
    id: text("id").primaryKey(),
    contentId: text("contentId").notNull(),
    url: text("url").notNull(),
    description: text("description"),
    title: text("title"),
    image: text("image"),
    linkOrder: integer("linkOrder").default(0).notNull(),
  },
  (table) => ({
    contentUrlUnique: uniqueIndex("link_contentId_url_unique").on(table.contentId, table.url),
  }),
);

// audio_encoding table
export const audioEncoding = pgTable(
  "audio_encoding",
  {
    id: text("id").primaryKey(),
    contentId: text("contentId").notNull(),
    codec: text("codec").notNull(),
    mimeType: text("mimeType"),
    duration: decimal("duration").notNull(),
    url: text("url").notNull(),
    transcriptionId: text("transcriptionId"),
    transcriptionType: text("transcriptionType"),
    language: text("language"),
    translatedFrom: text("translatedFrom"),
    priority: integer("priority").default(0).notNull(),
    generatedVoice: text("generatedVoice"),
    generatedService: text("generatedService"),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    contentIdIndex: index("audio_encoding_contentId_idx").on(table.contentId, table.language),
  }),
);

// transcription table
export const transcription = pgTable(
  "transcription",
  {
    id: text("id").primaryKey(),
    contentId: text("contentId").notNull(),
    transcriptionType: text("transcriptionType").notNull(),
    transcriptionContent: text("transcriptionContent").notNull(),
    language: text("language").default("en").notNull(),
    translatedFrom: text("translatedFrom"),
    backendModel: text("backendModel"),
    priority: integer("priority").default(0).notNull(),
    confidence: decimal("confidence"),
    executionTime: decimal("executionTime").default("0").notNull(),
    format: text("format").default("Text").notNull(),
    url: text("url"),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    contentIdLanguageIndex: index("transcription_contentId_idx").on(table.contentId, table.language),
  }),
);

// pipeline_artifact_metadata table
export const pipelineArtifactMetadata = pgTable(
  "pipeline_artifact_metadata",
  {
    id: text("id").primaryKey(),
    contentId: text("contentId").notNull(),
    vadResult: text("vadResult"),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    contentIdIndex: index("pipeline_artifact_metadata_contentId_idx").on(table.contentId),
  }),
);

// feed table
export const feed = pgTable(
  "feed",
  {
    id: text("id").primaryKey(),
    title: text("title"),
    workspaceId: text("workspaceId"),
    feedType: text("feedType").default("userCreated").notNull(),
    readOnly: boolean("readOnly").default(false).notNull(),
    isPrivate: boolean("isPrivate").default(false).notNull(),
    isDm: boolean("isDm").default(false).notNull(),
    isSilent: boolean("isSilent").default(false).notNull(),
    autoJoin: boolean("autoJoin").default(false).notNull(),
    loadedFirstPage: boolean("loadedFirstPage").default(false).notNull(),
    loadedLastPage: boolean("loadedLastPage").default(false).notNull(),
    loadedEvents: boolean("loadedEvents").default(false).notNull(),
    loadedPermissions: boolean("loadedPermissions").default(false).notNull(),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
    latestActivity: timestamp("latestActivity", {
      mode: "string",
      withTimezone: true,
    }),
    latestAliasActivity: timestamp("latestAliasActivity", {
      mode: "string",
      withTimezone: true,
    }),
    lastOpened: timestamp("lastOpened", { mode: "string", withTimezone: true }),
    aliasMembershipId: text("aliasMembershipId"),
    isAliasFeed: boolean("isAliasFeed").default(false).notNull(),
  },
  (table) => ({
    workspaceDmLatestActivityIndex: index("feed_workspaceId_isDm_latestActivity_idx").on(
      table.workspaceId,
      table.isDm,
      desc(table.latestActivity),
    ),
  }),
);

// item table
export const item = pgTable(
  "item",
  {
    id: text("id").primaryKey(),
    feedId: text("feedId").notNull(),
    contentId: text("contentId").notNull(),
    groupId: text("groupId"),
    deletedAt: timestamp("deletedAt", { mode: "string", withTimezone: true }),
    accountId: text("accountId"),
    loadedContent: boolean("loadedContent").default(false),
    isSilent: boolean("isSilent").default(false).notNull(),
    unread: boolean("unread").default(false),
    isFromAliasMember: boolean("isFromAliasMember").default(false),
    createdAt: timestamp("createdAt", {
      mode: "string",
      withTimezone: true,
    }).notNull(),
    status: text("status").default("Completed"),
    fromAppSync: boolean("fromAppSync").default(false),
    firstInsert: boolean("firstInsert").default(true),
    hasRegisteredSeenEvent: boolean("hasRegisteredSeenEvent").default(false),
  },
  (table) => ({
    feedUnreadIndex: index("item_feedId_unread_idx").on(table.feedId, table.unread),
    unreadIndex: index("item_unread_idx").on(table.unread, table.deletedAt),
    feedCreatedAtDeletedIndex: index("item_feedId_createdAt_deletedAt_idx").on(
      table.feedId,
      table.deletedAt,
      asc(table.createdAt),
    ),
    feedCreatedAtIndex: index("item_feedId_createdAt_idx").on(table.feedId, desc(table.createdAt)),
    feedCreatedAtAccountIdIndex: index("item_feedId_accountId_createdAt_idx").on(
      table.feedId,
      table.accountId,
      desc(table.createdAt),
    ),
    feedIsFromAliasMemberCreatedAtIndex: index("item_feedId_isFromAliasMember_createdAt_idx").on(
      table.feedId,
      table.isFromAliasMember,
      desc(table.createdAt),
    ),
    feedContentIdIndex: index("item_feedId_contentId_idx").on(table.feedId, table.contentId),
  }),
);

// account table
export const account = pgTable(
  "account",
  {
    id: text("id").primaryKey(),
    firstName: text("firstName"),
    lastName: text("lastName"),
    email: text("email"),
    emailVerified: boolean("emailVerified").default(false).notNull(),
    phoneNumber: text("phoneNumber"),
    phoneNumberVerified: boolean("phoneNumberVerified").default(false).notNull(),
    name: text("name"),
    avatarColor: text("avatarColor"),
    accountType: text("accountType"),
    mine: boolean("mine").default(false).notNull(),
    preferredLanguage: text("preferredLanguage"),
  },
  (table) => ({
    mineIndex: index("account_mine_idx").on(table.mine),
  }),
);

// permission table
export const permission = pgTable(
  "permission",
  {
    id: text("id").primaryKey(),
    name: text("name").notNull(),
    accountId: text("accountId"),
    workspaceMembershipId: text("workspaceMembershipId"),
    feedId: text("feedId"),
    workflowItemId: text("workflowItemId"),
    enabled: boolean("enabled").notNull(),
    expiresAt: timestamp("expiresAt", { mode: "string", withTimezone: true }),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    workspaceMembershipFeedNameUnique: uniqueIndex("permission_workspaceMembershipId_feedId_name_unique").on(
      table.workspaceMembershipId,
      table.feedId,
      table.name,
    ),
    permissionNameAndFeedIndex: index("permission_name_feedId_idx").on(table.feedId, table.name),
    permissionNameAndWorkspaceMembershipIndex: index("permission_name_workspaceMembershipId_idx").on(
      table.workspaceMembershipId,
      table.name,
    ),
    workflowItemIdIndex: index("permission_workflowItemId_idx").on(table.workflowItemId),
  }),
);

// account_event table
export const accountEvent = pgTable(
  "account_event",
  {
    id: text("id").primaryKey(),
    accountId: text("accountId").notNull(),
    name: text("name").notNull(),
    memberAvailability: text("memberAvailability"),
    deviceVolume: real("deviceVolume"),
    createdAt: timestamp("createdAt", {
      mode: "string",
      withTimezone: true,
    }).notNull(),
    feedId: text("feedId"),
    itemId: text("itemId"),
    contentId: text("contentId"),
  },
  (table) => ({
    accountFeedItemContentNameUnique: uniqueIndex("account_event_accountId_feedId_itemId_contentId_name_unique").on(
      table.accountId,
      table.feedId,
      table.itemId,
      table.contentId,
      table.name,
    ),
    createdAtIndex: index("account_event_createdAt_idx").on(desc(table.createdAt)),
    memberAvailabilityIndex: index("account_event_memberAvailability_idx").on(desc(table.memberAvailability)),
    feedCreatedAtIndex: index("account_event_feedId_createdAt_idx").on(table.feedId, desc(table.createdAt)),
    itemCreatedAtIndex: index("account_event_itemId_createdAt_idx").on(table.itemId, desc(table.createdAt)),
  }),
);

// workspace table
export const workspace = pgTable(
  "workspace",
  {
    id: text("id").primaryKey(),
    name: text("name").notNull(),
    createdAt: timestamp("createdAt", {
      mode: "string",
      withTimezone: true,
    }).notNull(),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    nameIndex: index("workspace_name_idx").on(table.name),
  }),
);

// workspace_membership table
export const workspaceMembership = pgTable(
  "workspace_membership",
  {
    id: text("id").primaryKey(),
    workspaceId: text("workspaceId").notNull(),
    accountId: text("accountId"),
    status: text("status").notNull(),
    role: text("role").notNull(),
  },
  (table) => ({
    workspaceAccountUnique: uniqueIndex("workspace_membership_workspaceId_accountId_unique").on(
      table.workspaceId,
      table.accountId,
    ),
    accountIdIndex: index("workspace_membership_accountId_idx").on(table.accountId),
  }),
);

// workspace_command_alias table
export const workspaceCommandAlias = pgTable(
  "workspace_command_alias",
  {
    id: text("id").primaryKey(),
    alias: text("alias").notNull(),
    workspaceId: text("workspaceId").notNull(),
    feedId: text("feedId"),
    workspaceMembershipId: text("workspaceMembershipId").notNull(),
    createdAt: timestamp("createdAt", {
      mode: "string",
      withTimezone: true,
    }).notNull(),
  },
  (table) => ({
    workspaceAliasIndex: index("workspace_command_alias_workspaceId_alias_idx").on(table.workspaceId, table.alias),
    feedIdIndex: index("workspace_command_alias_feedId_idx").on(table.feedId),
    workspaceMembershipIdIndex: index("workspace_command_alias_workspaceMembershipId_idx").on(
      table.workspaceMembershipId,
    ),
  }),
);

// app_phone_number table
export const appPhoneNumber = pgTable("app_phone_number", {
  id: text("id").primaryKey(),
  phoneNumber: text("phoneNumber").notNull(),
  forwardingNumber: text("forwardingNumber"),
  feedId: text("feedId"),
});

// call_record table
export const callRecord = pgTable("call_record", {
  id: text("id").primaryKey(),
  contentId: text("contentId").notNull(),
  fromAccountId: text("fromAccountId").notNull(),
  fromDisplayName: text("fromDisplayName").notNull(),
  fromAlias: text("fromAlias").notNull(),
  toAccountId: text("toAccountId").notNull(),
  toDisplayName: text("toDisplayName").notNull(),
  toAlias: text("toAlias").notNull(),
  durationSeconds: integer("durationSeconds"),
  callStatus: text("callStatus").notNull(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
});

// direct_ws_invitation table
export const directWsInvitation = pgTable(
  "direct_ws_invitation",
  {
    id: text("id").primaryKey(),
    workspaceMembershipId: text("workspaceMembershipId").notNull(),
    email: text("email"),
    phoneNumber: text("phoneNumber"),
    name: text("name"),
    claimedAt: timestamp("claimedAt", { mode: "string", withTimezone: true }),
    claimedBy: text("claimedBy"),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    workspaceMembershipIdIndex: index("direct_ws_invitation_workspaceMembershipId_idx").on(table.workspaceMembershipId),
  }),
);

// audio_queue_item table
export const audioQueueItem = pgTable(
  "audio_queue_item",
  {
    id: text("id").primaryKey(),
    itemId: text("itemId"),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    itemIdIndex: index("audio_queue_item_itemId_idx").on(table.itemId),
  }),
);

// display_artifact table
export const displayArtifact = pgTable(
  "display_artifact",
  {
    id: text("id").primaryKey(),
    title: text("title").notNull(),
    description: text("description"),
    contentId: text("contentId").notNull(),
    deletedAt: timestamp("deletedAt", { mode: "string", withTimezone: true }),
    createdAt: timestamp("createdAt", {
      mode: "string",
      withTimezone: true,
    }).notNull(),
  },
  (table) => ({
    contentIdUnique: uniqueIndex("display_artifact_contentId_unique").on(table.contentId),
  }),
);

// workflow_item table
export const workflowItem = pgTable("workflow_item", {
  id: text("id").primaryKey(),
  workspaceId: text("workspaceId").notNull(),
  contentId: text("contentId").notNull(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  deletedAt: timestamp("deletedAt", { mode: "string", withTimezone: true }),
  displayName: text("displayName"),
});

// published_workflow_item table
export const publishedWorkflowItem = pgTable("published_workflow_item", {
  id: text("id").primaryKey(),
  workflowItemId: text("workflowItemId").notNull(),
  broadcastId: text("broadcastId").notNull(),
  accountId: text("accountId").notNull(),
  workspaceId: text("workspaceId").notNull(),
  contentId: text("contentId").notNull(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
});

// broadcast table
export const broadcast = pgTable("broadcast", {
  id: text("id").primaryKey(),
  workspaceId: text("workspaceId").notNull(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
});

// broadcast_recipient table
export const broadcastRecipient = pgTable("broadcast_recipient", {
  id: text("id").primaryKey(),
  broadcastId: text("broadcastId").notNull(),
  workspaceId: text("workspaceId").notNull(),
  feedId: text("feedId"),
  workspaceMembershipId: text("workspaceMembershipId"),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
});

// schedule_trigger table
export const scheduleTrigger = pgTable("schedule_trigger", {
  id: text("id").primaryKey(),
  oneTimeSchedule: text("oneTimeSchedule"),
  cronSchedule: text("cronSchedule"),
  workspaceId: text("workspaceId").notNull(),
  broadcastActionId: text("broadcastActionId"),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  timezone: text("timezone"),
  // enabled should be a boolean, not a integer
  enabled: integer("enabled").default(1).notNull(),
});

// broadcast_action table
export const broadcastAction = pgTable("broadcast_action", {
  id: text("id").primaryKey(),
  workspaceId: text("workspaceId").notNull(),
  workflowItemId: text("workflowItemId").notNull(),
  broadcastId: text("broadcastId").notNull(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
});

// template table
export const template = pgTable(
  "template",
  {
    id: text("id").primaryKey(),
    name: text("name").notNull(),
    template: text("template").notNull(),
    workspaceId: text("workspaceId").notNull(),
    authorId: text("authorId").notNull(),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
    deletedAt: timestamp("deletedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    workspaceIdIndex: index("template_workspaceId_idx").on(table.workspaceId),
  }),
);

// ws_membership_availability table
export const wsMembershipAvailability = pgTable(
  "ws_membership_availability",
  {
    workspaceMembershipId: text("workspaceMembershipId").primaryKey(),
    workspaceId: text("workspaceId").notNull(),
    calculatedAvailability: text("calculatedAvailability").notNull(),
    mostRecentAvailabilityChange: timestamp("mostRecentAvailabilityChange", {
      mode: "string",
      withTimezone: true,
    }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
    mostRecentHandsFreeStatusEnabled: boolean("mostRecentHandsFreeStatusEnabled"),
    mostRecentHandsFreeStatusChangedAt: timestamp("mostRecentHandsFreeStatusChangedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentHandsFreeStatusUpdatedAt: timestamp("mostRecentHandsFreeStatusUpdatedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentVolumeVolume: real("mostRecentVolumeVolume"),
    mostRecentVolumeAudible: boolean("mostRecentVolumeAudible").default(false),
    mostRecentVolumeChangedAt: timestamp("mostRecentVolumeChangedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentVolumeUpdatedAt: timestamp("mostRecentVolumeUpdatedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDutyStatusStatus: text("mostRecentDutyStatusStatus"),
    mostRecentDutyStatusSource: text("mostRecentDutyStatusSource"),
    mostRecentDutyStatusChangedAt: timestamp("mostRecentDutyStatusChangedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDutyStatusUpdatedAt: timestamp("mostRecentDutyStatusUpdatedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDrivingStatus: text("mostRecentDrivingStatus"),
    mostRecentDrivingStatusChangedAt: timestamp("mostRecentDrivingStatusChangedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDrivingStatusUpdatedAt: timestamp("mostRecentDrivingStatusUpdatedAt", {
      mode: "string",
      withTimezone: true,
    }),
    status: text("status"),
    mostRecentDeviceLocationLatitude: real("mostRecentDeviceLocationLatitude"),
    mostRecentDeviceLocationLongitude: real("mostRecentDeviceLocationLongitude"),
    mostRecentDeviceLocationChangedAt: timestamp("mostRecentDeviceLocationChangedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDeviceLocationUpdatedAt: timestamp("mostRecentDeviceLocationUpdatedAt", {
      mode: "string",
      withTimezone: true,
    }),
    mostRecentDeviceLocationNearestAddressMunicipality: text("mostRecentDeviceLocationNearestAddressMunicipality"),
    mostRecentDeviceLocationNearestAddressRegion: text("mostRecentDeviceLocationNearestAddressRegion"),
    mostRecentDeviceLocationNearestAddressCountry: text("mostRecentDeviceLocationNearestAddressCountry"),
  },
  (table) => ({
    wsMembershipAvailabilityWorkspaceIdIndex: index("ws_membership_availability_workspaceId_idx").on(table.workspaceId),
    wsMembershipAvailabilityIdIndex: index("ws_membership_availability_workspaceMembershipId_idx").on(
      table.workspaceMembershipId,
    ),
  }),
);

// feed_group table
export const feedGroup = pgTable(
  "feed_group",
  {
    id: text("id").primaryKey(),
    name: text("name").notNull(),
    accountId: text("accountId"),
    workspaceId: text("workspaceId").notNull(),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
    enabled: integer("enabled").default(1).notNull(),
  },
  (table) => ({
    workspaceIdIndex: index("feed_group_workspaceId_idx").on(table.workspaceId),
  }),
);

// feed_group_membership table
export const feedGroupMembership = pgTable(
  "feed_group_membership",
  {
    id: text("id").primaryKey(),
    workspaceId: text("workspaceId").notNull(),
    feedId: text("feedId").notNull(),
    groupId: text("groupId").notNull(),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
    enabled: integer("enabled").default(1).notNull(),
  },
  (table) => ({
    feedIdIndex: index("feed_group_membership_feedId_idx").on(table.feedId),
    groupIdIndex: index("feed_group_membership_groupId_idx").on(table.groupId),
    workspaceIdIndex: index("feed_group_membership_workspaceId_idx").on(table.workspaceId),
  }),
);

// device_registration table
export const deviceRegistration = pgTable("device_registration", {
  id: text("id").primaryKey(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  surface: text("surface"),
  deviceOsName: text("deviceOsName"),
  deviceOsVersion: text("deviceOsVersion"),
  deviceModel: text("deviceModel"),
  friendlyName: text("friendlyName"),
  drivingDetectionEnabled: boolean("drivingDetectionEnabled").default(true).notNull(),
  wakeWordSensitivity: decimal("wakeWordSensitivity").default("0.3").notNull(),
  commandSensitivity: decimal("commandSensitivity").default("0.2").notNull(),
  silenceDetectionInitialThreshold: decimal("silenceDetectionInitialThreshold").default("0.1").notNull(),
  silenceDetectionFinalThreshold: decimal("silenceDetectionFinalThreshold").default("0.25").notNull(),
  commandTimingWindowMs: integer("commandTimingWindowMs").default(2500).notNull(),
  recordingTimeoutWindowMs: integer("recordingTimeoutWindowMs").default(3000).notNull(),
});

export const feedPreference = pgTable(
  "feed_preference",
  {
    id: text("id").primaryKey(),
    feedId: text("feedId").notNull(),
    workspaceMembershipId: text("workspaceMembershipId").notNull(),
    handsFreeNotificationPreference: text("handsFreeNotificationPreference").default("none").notNull(),
    pushNotificationPreference: text("pushNotificationPreference").default("none").notNull(),
    createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
    updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  },
  (table) => ({
    feedIdIndex: index("feed_preference_feedId_idx").on(table.feedId),
    workspaceMembershipIdIndex: index("feed_preference_workspaceMembershipId_idx").on(table.workspaceMembershipId),
  }),
);

export const callStateType = pgEnum("call_state_type", [
  "queued",
  "errored",
  "ringing",
  "canceled",
  "rejected",
  "accepted",
  "disconnected",
  "reconnecting",
  "reconnected",
]);

export const callConfiguration = pgTable("call_configuration", {
  id: text("id").primaryKey(),
  createdAt: timestamp("createdAt", { mode: "string", withTimezone: true }),
  updatedAt: timestamp("updatedAt", { mode: "string", withTimezone: true }),
  fromAlias: text("fromAlias").notNull(),
  fromAccountId: text("fromAccountId").notNull(),
  fromDisplayName: text("fromName").notNull(),
  fromToken: text("fromToken").notNull(),
  toAlias: text("toAlias").notNull(),
  toAccountId: text("toAccountId").notNull(),
  toDisplayName: text("toName").notNull(),
  toToken: text("toToken").notNull(),
  initiate: boolean("initiate").notNull(),
  state: callStateType("state").notNull(),
});
