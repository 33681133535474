import SimpleModal from "@/components/SimpleModal";
import { useCall } from "@/contexts/CallContext";
import { AccountWithWsMembershipAvailability } from "@/db/types";
import UseTimeouts from "@/hooks/useTimeouts";
import { getPaddedTime } from "@/utils";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { SxProps, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import useSound from "use-sound";
import boopSfx from "../audio/rings.mp3";
export default function MakeCall({
  feedId,
  myAccountId,
  accountRecordWithStatus,
  handsFreeEnabled,
}: {
  feedId: string;
  myAccountId: string;
  accountRecordWithStatus: AccountWithWsMembershipAvailability;
  handsFreeEnabled: boolean;
}) {
  const theme = useTheme();
  const { createCallAndListen, currentCall, callTextStatus, disconnectCall } = useCall();
  const [startCallTime, setStartCallTime] = useState<number>(0);
  const [activeCall, setActiveCall] = useState<boolean>(false);
  const name = accountRecordWithStatus?.name ?? "Unknown";
  const { refresh } = UseTimeouts(1);
  const [play, { stop }] = useSound(boopSfx, { volume: 0.5 });

  const startCall = async () => {
    setActiveCall(() => true);
    await createCallAndListen({ fromAccountId: accountRecordWithStatus.id, toAccountId: myAccountId, feedId });
  };

  useEffect(() => {
    if (callTextStatus === "ringing") {
      play();
    } else {
      stop();
    }

    if (callTextStatus === "accepted" && !startCallTime) {
      setStartCallTime(() => 0);
    } else {
      setStartCallTime(() => 0);
    }
  }, [callTextStatus]);

  const endCall = () => {
    setActiveCall(() => false);
    setStartCallTime(() => 0);
    stop();
  };
  useEffect(() => {
    if (refresh && callTextStatus === "accepted") {
      setStartCallTime((prev) => prev + 1);
    }
  }, [refresh, callTextStatus, startCallTime]);

  useEffect(() => {
    // disconnect call if hands free is disabled
    if (!handsFreeEnabled && activeCall) {
      endCall();
      disconnectCall("disconnected");
    }
  }, [handsFreeEnabled]);

  const callModalWindow = (
    <SimpleModal
      sx={{ width: "400px", px: 2 }}
      disableClose={true}
      open={activeCall}
      closeModal={() => {
        setActiveCall(() => false);
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", height: "100%", gap: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
            {name}
          </Typography>

          {callTextStatus === "ringing" && <Box>Ringing</Box>}
          {callTextStatus === "accepted" && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>Connected</Box> <Box>{getPaddedTime(startCallTime)}</Box>
            </Box>
          )}
        </Box>
        <Box>
          <Box
            component="button"
            className="basic-button"
            sx={{
              borderRadius: "100%",
              background: theme.palette.error.main,
              width: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 60,
              "&:hover": {
                background: theme.palette.error.dark,
              },
            }}
            onClick={() => {
              endCall();
              disconnectCall("canceled");
            }}
          >
            <CallEndIcon sx={{ fontSize: "30px" }} />
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  );

  const buttonProps = {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  };

  const errorButtonProps = {
    ...buttonProps,
    background: theme.palette.error.light,
    "&:hover": {
      background: theme.palette.error.main,
    },
  };

  const successButtonProps = {
    ...buttonProps,
    background: theme.palette.success.light,
    "&:hover": {
      background: theme.palette.success.main,
    },
  };

  const callButtonWithModal = ({ disabled, sx }: { disabled?: boolean; sx?: SxProps }) => {
    return (
      <>
        {callModalWindow}
        {activeCall ? (
          <Box
            disabled={disabled}
            component="button"
            className={`basic-button ${disabled ? "disabled" : ""}`}
            sx={{ ...errorButtonProps, ...sx }}
            onClick={() => {
              endCall();
              disconnectCall("canceled");
            }}
          >
            <CallEndIcon sx={{ fontSize: "20px" }} />
          </Box>
        ) : (
          <Box
            disabled={disabled}
            component="button"
            className={`basic-button ${disabled ? "disabled" : ""}`}
            sx={{ ...successButtonProps, ...sx }}
            onClick={() => startCall()}
          >
            <CallIcon sx={{ fontSize: "20px" }} />
          </Box>
        )}
      </>
    );
  };

  return {
    activeCall,
    callModalWindow,
    callButtonWithModal,
    startCall,
    endCall,
  };
}
