import FeedItem from "@/components/FeedItem/FeedItem";
import FeedItemHeader from "@/components/FeedItem/FeedItemHeader";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TelemetryContext, actions } from "@/models/TelemetryProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { downloadFeedItemContent } from "@/models/actions/initialFeedLoad";
import { fetchFeedCommandAliasesQuery } from "@/models/commonQueries";
import { ArrowDownward } from "@mui/icons-material";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { type Dispatch, type SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { VList } from "virtua";

export default function FeedVirtualizedList({
  setRenderToggle,
}: { setRenderToggle: Dispatch<SetStateAction<string>> }) {
  const { feedMap, vListRef, currentFeed } = useContext(FeedContext);
  const theme = useTheme();
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { preferredLanguage } = useContext(DataContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const { accountEvent } = useContext(ActionContext);
  const { ampli } = useContext(TrackingContext);
  const wls = window?.location?.search; // window location search
  const feedItemId = new URLSearchParams(wls).get("feedItemId") ?? null;
  const { hash } = useLocation();
  const hashValue = hash?.split("#")?.[1] ?? null;
  const hasContentId = hashValue || feedItemId;
  const [activeHashOrFeedItemId, setActiveHashOrFeedItemId] = useState<string | null>(hasContentId);
  const [activeFeedItemIndex, setActiveFeedItemIndex] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [range, setRange] = useState<number[]>([0, 0]);
  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);
  const [allowJumpButton, setAllowJumpButton] = useState<boolean>(false);
  const [preventScroll, setPreventScroll] = useState<boolean>(false);
  const initialContentLoad = useRef<boolean>(true);
  const countThreshold = 5;
  const feedId = currentFeed?.id;
  const workspaceId = currentWorkspaceId;

  const { rows: aliasChannelDetails } = useDrizzleSelect(fetchFeedCommandAliasesQuery(currentFeed.id));

  const aliasChannelOwnerId = aliasChannelDetails?.[0]?.id ?? null;

  const feedMapIds = useMemo(
    () => feedMap?.filter((feedItem) => feedItem?.id !== feedId)?.map((feedItem) => feedItem.id),
    [feedMap, feedId],
  );

  const removeHash = (hashId: string) => {
    const hash = window.location.hash;
    const search = window.location.search?.replace("?feedItemId=", "");
    const hasHashValue = hash?.length > 0 ? hash?.replace("#", "") : search?.length > 0 ? search : null;

    if (!hasHashValue || hasHashValue?.length === 0 || hasHashValue !== hashId) {
      return;
    }

    if (window.location.hash || window.location.search?.includes("?feedItemId=")) {
      window.history.replaceState("", document.title, window.location.pathname);
      setActiveFeedItemIndex(() => null);
    }
    setActiveHashOrFeedItemId(() => null);
  };

  const setPreventScrollAction = () => {
    setPreventScroll(() => true);
    setTimeout(() => {
      setPreventScroll(() => false);
    }, 500);
  };

  const downloadFeedItems = async () => {
    const lowerRange = range[0] - 20;
    const upperRange = range[1] + 20;
    const lowerBound = lowerRange > 0 ? lowerRange : 0;
    const upperBound = upperRange < feedMap?.length ? upperRange : feedMap?.length;
    const feedMapRange = feedMap.slice(lowerBound, upperBound);

    const idMap = feedMapRange
      .filter((item) => !item.loadedContent)
      .map((item) => item.id)
      .filter((item) => item);

    const spanName = initialContentLoad.current ? actions.feedInitial(currentFeed) : actions.feedScroll(currentFeed);

    if (idMap?.length > 0) {
      trackAction(spanName, {
        feedId,
        "feed.itemsLoaded": idMap.length.toString(),
      });
      initialContentLoad.current = false;

      await downloadFeedItemContent(workspaceId, idMap, preferredLanguage);
      setRenderToggle(feedId);
      finishAction(spanName);
    }
  };

  const scrollToIndex = (index: number) => {
    if (preventScroll) {
      setTimeout(() => setPreventScroll(() => false), 250);
      return;
    }
    vListRef.current?.scrollToIndex(index, { align: "start" });
    setAllowJumpButton(() => true);
  };

  const handRangeChange = ({ startIndex, endIndex }: { startIndex: number; endIndex: number }) => {
    if (endIndex === feedMap?.length - 1) {
      setShowScrollToBottom(() => false);
    } else {
      setShowScrollToBottom(() => endIndex <= feedMap?.length - 1 && feedMap?.length > countThreshold);
    }
    const range = startIndex > 0 ? [startIndex - 5, endIndex + 5] : [0, 10];
    setRange(() => range);
  };

  const findFirstUnreadIndex = feedMap?.findIndex((feedItem) => feedItem.unread === true);
  const unreadCount = feedMap?.filter((item) => item.unread)?.length;

  const jumpToLastItem = () => {
    scrollToIndex(feedMap?.length - 1);
    setPreventScrollAction();
  };

  /**
   * useEffects ➞
   *    0. Download Feed Items
   *    1. Reset values ➞ Don't Scroll
   *    2. Reset values ➞ Don't Scroll
   *    3. Set unread active item ➞ Don't Scroll
   *    4. If hashId exists set active hash ➞ Don't Scroll
   *    5. On feed change scroll to bottom ➞ Scroll
   *    6. Mark unread items ➞ Scroll
   */

  // 0. Download feed items
  useEffect(() => {
    downloadFeedItems();
  }, [preferredLanguage, feedId]);

  // 1. Scroll to unread items when they appear
  useEffect(() => {
    if (unreadCount > 0) {
      scrollToIndex(feedMapIds?.length - 1);
    }
  }, [unreadCount, feedMapIds?.length]);

  // 2. Reset values
  useEffect(() => {
    setActiveFeedItemIndex(() => null);
    setPreventScroll(() => false);
    setShowScrollToBottom(() => false);
    setAllowJumpButton(() => false);
    setActiveHashOrFeedItemId(() => null);
    setActiveIndex(() => -1);
    initialContentLoad.current = true;
    accountEvent("Opened Feed", {
      feedId,
    });
    ampli.feedOpen({ feedId });
  }, [feedId]);

  // 3. Set unread active item
  useEffect(() => {
    setActiveIndex(() => findFirstUnreadIndex);
  }, [feedMap?.length]);

  // 4. If hashId exists scroll to the item
  useEffect(() => {
    if (hasContentId) {
      setActiveHashOrFeedItemId(() => hasContentId);
    }
  }, [hasContentId]);

  // 5. On feed change scroll to bottom
  useEffect(() => {
    if (activeHashOrFeedItemId) {
      // scroll to item after 250ms
      setTimeout(() => {
        const scrollPosition = feedMap?.findIndex((feedItem) => feedItem.id === activeHashOrFeedItemId);
        if (scrollPosition && scrollPosition > -1) {
          scrollToIndex(scrollPosition);
          setActiveFeedItemIndex(() => `${feedId}:${scrollPosition}`);
        }
      }, 250);
      // remove hash after 5 seconds
      setTimeout(() => {
        removeHash(activeHashOrFeedItemId);
      }, 5000);
    }
  }, [feedId, activeHashOrFeedItemId, feedMapIds]);

  // 6. Mark unread items ➞ Scroll
  useEffect(() => {
    if (!activeHashOrFeedItemId) {
      if (findFirstUnreadIndex > 0) {
        scrollToIndex(findFirstUnreadIndex);
        setActiveIndex(() => findFirstUnreadIndex);
      } else {
        scrollToIndex(feedMapIds?.length - 1);
      }
    }
  }, [feedId]);

  const JumpToLatestMessage = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: "1rem",
          left: 0,
          display: "flex",
          zIndex: 300,
        }}
      >
        <Box
          sx={{
            maxWidth: "1300px",
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Chip
            id="jumpToLatestMessage"
            role="button"
            onClick={() => jumpToLastItem()}
            sx={{
              background: theme.palette.brand.secondary.main,
              "&:hover": { background: theme.palette.brand.secondary.light },
            }}
            label={
              <Box sx={{ display: "flex", alignItem: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowDownward role="img" sx={{ fontSize: "1rem", mr: 1 }} />
                </Box>
                <Box>Jump to latest message</Box>
              </Box>
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      {showScrollToBottom && allowJumpButton && <JumpToLatestMessage />}
      <VList
        aria-label={Locator.feed.items.list}
        data-testid={Locator.feed.testId(feedId)}
        style={{
          height: "calc(100svh - 275px)",
          position: "relative",
          paddingRight: "6px",
          paddingLeft: "16px",
        }}
        count={feedMap?.length}
        reverse={true}
        shift={false}
        overscan={0}
        ref={vListRef}
        id="vListRef"
        itemSize={100}
        onRangeChange={(startIndex, endIndex) => {
          handRangeChange({ startIndex, endIndex });
        }}
        onScrollEnd={async () => {
          await downloadFeedItems();
        }}
      >
        {feedMap?.map((feedItem, index) => (
          <Box key={`feed-item-${feedItem?.id}`} sx={{ maxWidth: "1300px", margin: "0 auto" }}>
            {feedItem.isFirstDateGroupItem ? (
              <FeedItemHeader
                isStuck={false}
                dateId={feedItem.shortDateStr}
                onClick={() => {}}
                dateLabel={feedItem.dateGroupLabel}
                isUnreadHeader={false}
                unreadCount={0}
              />
            ) : (
              <Box sx={{ height: 34 }} />
            )}

            {activeIndex === index && (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "right",
                  borderBottom: `2px solid ${theme.palette.brand.secondary.main}`,
                  fontSize: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    color: theme.palette.brand.secondary.main,
                  }}
                >
                  New
                </Typography>
              </Box>
            )}
            <FeedItem
              preventScroll={(_value: boolean) => {
                setPreventScrollAction();
              }}
              setItemNotLoaded={async () => downloadFeedItems()}
              feed={currentFeed}
              item={feedItem}
              vListIndex={index}
              aliasChannelOwnerId={aliasChannelOwnerId}
              active={`${feedId}:${index}` === activeFeedItemIndex}
              highlightPodcast={`${feedId}:${index}` === activeFeedItemIndex}
            />
          </Box>
        ))}
      </VList>
    </Box>
  );
}
