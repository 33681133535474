import { CallRecord } from "@/db/types";
import { convertCamelCaseToCapitalize, dateDiff } from "@/utils";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import { Box, Typography } from "@mui/material";
import { formatDuration } from "date-fns";
import log from "loglevel";

interface DisplayCallRecordProps {
  callRecord: CallRecord;
  mine: boolean;
}

const DisplayCallRecord = ({ callRecord, mine }: DisplayCallRecordProps) => {
  log.info("[FeedItem][DisplayCallRecord] callRecord", callRecord);
  if (!callRecord) return null;

  // Format duration from seconds to minutes:seconds
  const formattedDuration = formatDuration({
    minutes: Math.floor(callRecord.durationSeconds / 60),
    seconds: callRecord.durationSeconds % 60,
  });

  // Determine if the current user was the caller or receiver
  // const userWasCaller = mine && callRecord.fromAccountId;
  // const callDirection = userWasCaller ? "Outgoing call" : "Incoming call";

  // Get the other party's name

  const createdAtDiff = dateDiff(callRecord.createdAt, 1);

  const noAnswer =
    createdAtDiff.diffInMinutes >= 1 &&
    callRecord.callStatus === "ringing" &&
    (callRecord.durationSeconds === 0 || callRecord.durationSeconds === null);

  const attributes = [
    {
      key: "ringing",
      className: "ringing-phone",
      active: false,
      icon: <SettingsPhoneIcon />,
      color: (theme) => theme.palette.info.main,
    },
    {
      key: "completed",
      className: "completed-call",
      active: false,
      icon: <CallEndIcon />,
      color: (theme) => theme.palette.success.light,
    },
    {
      key: "noAnswer",
      className: "no-answer",
      active: false,
      icon: <PhoneMissedIcon />,
      color: (theme) => theme.palette.error.main,
    },
    {
      key: "canceled",
      className: "canceled",
      active: false,
      icon: <PhoneDisabledIcon />,
      color: (theme) => theme.palette.error.main,
    },
    {
      key: "inProgress",
      className: "in-progress",
      active: false,
      icon: <PhoneInTalkIcon />,
      color: (theme) => theme.palette.info.main,
    },
    {
      key: "failed",
      className: "failed",
      active: false,
      icon: <PhoneDisabledIcon />,
      color: (theme) => theme.palette.error.dark,
    },
  ].map((item) => ({
    ...item,
    active: callRecord.callStatus === item.key,
  }));

  const filteredAttributes = attributes.filter((item) => item.active);
  const activeItem = filteredAttributes[0];
  const statusOverRide = convertCamelCaseToCapitalize(callRecord.callStatus);

  return (
    <Box
      sx={{
        my: 1,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.secondary.dark,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: activeItem?.color,
              color: "white",
              borderRadius: "100%",
              width: "50px",
              height: "50px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }} className={activeItem?.className}>
              {activeItem?.icon}
            </Box>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Call between {callRecord.fromDisplayName} and {callRecord.toDisplayName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status: {statusOverRide}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Duration: {formattedDuration ? formattedDuration : "0 seconds"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DisplayCallRecord;
