import { client } from "@/config";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { workspace } from "@/db/schema";
import { AccountWithWsMembershipAvailability, Workspace, WorkspaceMembership } from "@/db/types";
import UseTimeouts from "@/hooks/useTimeouts";
import { TelemetryContext } from "@/models/TelemetryProvider";
import { asc } from "drizzle-orm";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  mapForHasId,
  mapForHasWorkspaceMembershipId,
  workspaceAccountsWithStatusSelect,
  workspaceAvailabilitySize,
} from "../caching";
import { justWorkspaceMembershipsQuery, myWorkspaceMembershipsQuery } from "../commonQueries";
import { MyAccountContext } from "./myAccountProvider";

type WorkspaceState = {
  currentWorkspaceId?: string;
  myCurrentWorkspaceMembership?: WorkspaceMembership;
  workspaceMemberships?: WorkspaceMembership[];
  isWorkspaceAdmin?: () => boolean;
  isWorkspaceLimitedMember?: () => boolean;
  workspaces?: Workspace[];
  accountMap?: Map<string, AccountWithWsMembershipAvailability>;
  workspaceMembershipMap?: Map<string, AccountWithWsMembershipAvailability>;
};

export const WorkspaceContext = createContext<WorkspaceState>({});

const LOCATION_ENABLED = true;

const WorkspaceProvider = ({ children }) => {
  const { setAttribute } = useContext(TelemetryContext);
  const { myAccountId } = React.useContext(MyAccountContext);
  const { workspaceId } = useParams();
  const { sendDesktopHandsFree } = useFlags();
  const handsFreeAllowed = handsFreeEnabledForWorkspace(workspaceId);

  const { rows: workspaces } = useDrizzleSelect(db.select().from(workspace).orderBy(asc(workspace.name)));

  const currentWorkspaceId = workspaceId ?? workspaces[0]?.id;

  const { rows: myCurrentWorkspaceMemberships } = useDrizzleSelect(
    myWorkspaceMembershipsQuery({
      accountId: myAccountId,
      currentWorkspaceId,
    }),
  );

  const myCurrentWorkspaceMembership = myCurrentWorkspaceMemberships[0];

  const { rows: workspaceMemberships } = useDrizzleSelect(justWorkspaceMembershipsQuery({ workspaceId }));

  const isWorkspaceAdmin = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "admin";
  }, [myCurrentWorkspaceMembership]);

  const isWorkspaceLimitedMember = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "limitedMember";
  }, [myCurrentWorkspaceMembership]);

  useEffect(() => {
    if (myCurrentWorkspaceMembership?.role) {
      setAttribute("workspaceRole", myCurrentWorkspaceMembership?.role);
    }
  }, [myCurrentWorkspaceMembership?.role]);

  const { rows: accountsWithStatus } = useDrizzleSelect(workspaceAccountsWithStatusSelect(workspaceId)) as {
    rows: AccountWithWsMembershipAvailability[];
  };

  const { rows: wsAvailabilitySize } = useDrizzleSelect(workspaceAvailabilitySize(workspaceId));
  // create a mutable object to detect when workspace availability changes
  const truncatedSizeDiff = wsAvailabilitySize
    ?.map((item) =>
      Object.values(item)
        .filter((item) => item)
        .map((item) => item.toString().toLowerCase().replace(/:/g, "").replace(/ /g, ""))
        .join(""),
    )
    ?.join("");

  const accountMap = useMemo(() => mapForHasId(accountsWithStatus), [accountsWithStatus, truncatedSizeDiff]);

  const workspaceMembershipMap = useMemo(
    () => mapForHasWorkspaceMembershipId(accountsWithStatus),
    [workspaceMemberships],
  );

  const localHeartBeat = UseTimeouts(60);
  useEffect(() => {
    if (!handsFreeAllowed || !sendDesktopHandsFree) return;
    if (localHeartBeat?.refresh === false) {
      if (sendDesktopHandsFree && LOCATION_ENABLED) {
        const successCallback = (position: {
          coords: {
            latitude: number;
            longitude: number;
          };
        }) => {
          const timestamp = new Date().toISOString();
          const heartbeat = {
            timestamp,
          };
          client.heartbeat(workspaceId, heartbeat);
        };
        const errorCallback = () => {
          const timestamp = new Date().toISOString();
          const heartBeatNoGeo = {
            timestamp,
          };
          client.heartbeat(workspaceId, heartBeatNoGeo);
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else if (sendDesktopHandsFree) {
        const timestamp = new Date().toISOString();
        const heartBeatNoGeo = {
          timestamp,
        };
        client.heartbeat(workspaceId, heartBeatNoGeo);
      }
    }
  }, [handsFreeAllowed, localHeartBeat?.refresh, sendDesktopHandsFree, workspaceId]);

  return (
    <WorkspaceContext.Provider
      value={{
        currentWorkspaceId: workspaceId,
        myCurrentWorkspaceMembership,
        workspaceMemberships,
        workspaces,
        accountMap,
        workspaceMembershipMap,

        isWorkspaceAdmin,
        isWorkspaceLimitedMember,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
